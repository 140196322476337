import Estates from "../components/estates/Estates";
import Layout from "../components/layout/Layout";
import React from "react";

const EstatePage = () => {
  return (
    <Layout page="immobilien">
      <Estates />
    </Layout>
  );
};

export default EstatePage;
